import styled from "styled-components";
import styles from "../assets/styles";
import "./css/MobileTitle.css";
export function SectionTag({ title, color }) {
  return (
    <Box className="box" color={color}>
      <Title className="title">{title}</Title>
    </Box>
  );
}

const Box = styled.div`
  border: 4px solid white;
  background-color: ${(props) => props.color};
  border-radius: 8px;
  transform: rotate(89deg);
  padding: 0 24px;
`;

const Title = styled.h1`
  margin: 0px;
  font-size: 3.625rem;
  position: relative;
  font-family: ${styles.black};
  color: ${styles.white};
  transform: rotate(1deg);
  padding: 27.5px 8.8px;
  text-align: center;
`;
