import styled from "styled-components";
import styles from "../assets/styles";
import { useState } from "react";
import Navigation from "../components/Navigation";
import Pages from "../images/PAGES.png";
import { photoData, photoWebsite } from "./PhotoList";
import Photo11 from "../images/7.png";
import Web2 from "../images/Cr2.png";
import "../App.css";
function Portfolio() {
  const [webdesign, setWebDesign] = useState(Photo11);
  const [website, setWebsite] = useState(Web2);
  return (
    <Container>
      <Navigation />
      <HeaderImg src={Pages} className="port-image" />
      <MainContainer>
        <HeaderH1>Web Design.</HeaderH1>
        <DisplayContainer>
          <ImageDisplay src={webdesign} className="img-display" />
          <CardContiner className="card-container">
            {photoData.map((photo, i) => (
              <PhotoCover>
                <PhotoImage
                  src={photo.image}
                  onClick={() => {
                    setWebDesign(photo.image);
                  }}
                />
              </PhotoCover>
            ))}
          </CardContiner>
        </DisplayContainer>
        <HeaderH1>Web Develop.</HeaderH1>
        <DisplayContainer>
          <ImageDisplay src={website} className="img-display" />

          <CardContiner className="card-container">
            {photoWebsite.map((photo, i) => (
              <PhotoCover>
                <PhotoImage
                  src={photo.image}
                  onClick={() => {
                    setWebsite(photo.image);
                  }}
                />
              </PhotoCover>
            ))}
          </CardContiner>
        </DisplayContainer>
      </MainContainer>
    </Container>
  );
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${styles.dark};
`;
const HeaderImg = styled.img`
  height: 100%;
  min-height: 512px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
`;
const HeaderH1 = styled.h1`
  font-size: 1.5rem;
  color: #ffffff;
  font-family: ${styles.regular};
  align-self: center;
  margin: 17px;
`;
const MainContainer = styled.main`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const PhotoImage = styled.img`
  height: 150px;
  width: 250px;
  object-fit: auto;
  border: 1px solid #dddddd;
  cursor: pointer;
`;
const DisplayContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
const ImageDisplay = styled.img`
  width: 100%;
  height: 550px;
  object-fit: cover;
  object-position: top;
`;
const CardContiner = styled.section`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  background-color: #363636;
  margin: 8px;
  width: 100%;
  max-width: 1200px;
  overflow: auto;
`;

const PhotoCover = styled.div`
  margin: 0px 0px;
  object-fit: cover;
  object-position: top;
  background-color: ${styles.dark};
  padding: 4px;
  border: 1px solid #dddddd;
`;

export default Portfolio;
