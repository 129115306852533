import PilonGoogle from "../images/GooglePilon.png";
import PilonIos from "../images/IosPilon.png";
import Colloqioum from "../images/collqioun.jpg";
import Mandate from "../images/mandate.png";
import Bank from "../images/81688.png";
export const blogData = [
  {
    title: "Bank Mobile Expertise",
    date: "Oct 21, 2023",
    context: "I Have Experience developing a mobile project regarding the Bank Finance App, Developer Bank must be focused on more security implementation.",
    img: Bank
  },
  {
    title: "My Prefered NOT Demanding",
    date: "Sept 29, 2023",
    context:
      "I worked as a Web and Mobile Developer with Expertise in Javascript for more than a year and a half months. Note that I am currently employed and willing to wait for an exit.",
    img: Mandate,
  },
  {
    title: "Pilon Mobile Released in Google Playstore",
    date: "Sept 29, 2023",
    context:
      "Managed release the product in Google Play Store by submitting with my sign and author name",
    img: PilonGoogle,
  },
  {
    title: "Pilon Mobile Released in Apple Playstore",
    date: "Sept 27, 2023",
    context:
      "Managed release of the product in Apple Apps by validating and submitting all required using XCODE and testflight",
    img: PilonIos,
  },
  {
    title: "Colloqioum Research Thesis in Computer Science",
    date: "June 07, 2023",
    context:
      "We managed to compete in the thesis from our title and innovative system with the application of Natural Language Processing in Journalism; We earned the title of best in presentation.",
    img: Colloqioum,
  },
];
