import styled from "styled-components";
import styles from "../assets/styles";
import { Link } from "react-router-dom";
import "../App.css";
export const Navigation = () => {
  return (
    <NavigationContainer className="navigation-container">
      <HeaderLogo className="header-logo">MAFI</HeaderLogo>
      <NavBox>
        <NavLink to="/" className="nav-link">
          About Me
        </NavLink>
        <NavLink to="/career" className="nav-link">
          Careers
        </NavLink>
        <NavLink to="/portfolio" className="nav-link">
          Portfolio
        </NavLink>
        <NavLink to="/blogs" className="nav-link">
          Blogs
        </NavLink>
      </NavBox>
      <HeaderLogo></HeaderLogo>
    </NavigationContainer>
  );
};

const NavigationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: 55px;
  background-color: ${styles.cherry};
  padding: 0 24px;
`;
const HeaderLogo = styled.h1`
  font-size: 1.75rem;
  color: ${styles.white};
  font-family: ${styles.black};
`;

const NavBox = styled.nav`
  display: flex;
  flex-direction: row;
  gap: 27px;
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: ${styles.white};
  font-family: ${styles.regular};
  font-size: 1rem;
`;
export default Navigation;
