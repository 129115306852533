import styled from "styled-components";
import styles from "../assets/styles";
import { ICbar } from "../assets/image/ic-bottom-bar.svg";

export const StyledContainer = styled.div`
  display: flex;
  background-color: ${styles.ash};
  padding: 2.5% 6%;
  flex-directions: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrapper: no-wrapper;
`;
export const MainContainer = styled.main`
  max-width: 1537px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 0%;
  background-color: transparent;
  `

export const ColumnWrapper = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const RowWrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const shortFlex = styled.section`
flex: 0.2
flex-direction: column;
align-items: center;
justify-content: flex-start;
`;
