import styled from "styled-components";
import styles from "../assets/styles";
import Navigation from "../components/Navigation";
import Photo11 from "../images/7.png";
import { blogData } from "./bloglist";
import "../App.css";
function Blogs() {
  return (
    <>
      <Navigation />
      <Container>
        <BlogsSection>
          <BlogsH1>Blogs</BlogsH1>
          <BlogsH1>Know me more</BlogsH1>
        </BlogsSection>
        <MainSection>
          {blogData.map((item, index) => (
            <ContentContainer className="contentContainer" key={index}>
              <Header className="header">
                <Title className="title">{item.title}</Title>
                <Date>{item.date}</Date>
              </Header>
              <Context>{item.context}</Context>
              <ImageDisplay src={item.img} className="imageDisplay" />
            </ContentContainer>
          ))}
        </MainSection>
      </Container>
    </>
  );
}
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #f2f2f2;
  height: auto;
`;
const BlogsSection = styled.section`
  width: 90%;
  max-width: 919px;
  height: 64px;
  background-color: ${styles.white};
  margin: 14px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
`;
const BlogsH1 = styled.h1`
  font-size: 1rem;
  font-family: ${styles.regular};
  margin: 16px;
`;
const MainSection = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;
`;
const ContentContainer = styled.section`
  height: auto;
  width: 919px;
  background-color: ${styles.white};
  border-radius: 10px;
  margin-top: 24px;
  padding: 24px 0px;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 23px;
  overflow: hidden;
`;
const Title = styled.h1`
  flex: 1;
  font-size: 1.4rem;
  color: ${styles.dark};
  font-family: ${styles.medium};
  word-break: break-all;
  text-align: justify;
`;
const Date = styled.p`
  font-size: 1rem;
  flex: 0.25;
  font-family: ${styles.regular};
  text-align: right;
`;
const Context = styled.p`
  padding: 0px 23px;
  font-size: 1rem;
  font-family: ${styles.regular};
  color: ${styles.light};
  text-align: justify;
  word-break: break-all;
  overflow: hidden;
  `;
const ImageDisplay = styled.img`
  margin: 0px 23px;
  width: 94%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
  object-position: top;
  border: 0.5px solid gray;
`;
export default Blogs;
