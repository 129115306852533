import styled from "styled-components";
import styles from "../assets/styles";
import Navigation from "../components/Navigations";
import Footer from "../components/Footer";
import b from "../assets/image/bullet.svg";

function Contact() {
  const contact = [
    {
      display: "Email: markangeloignacio001@gmail.com",
      link: "mailto:markangeloignacio001@gmail.com",
    },
    {
      display: "Facebook: Angelo Mark Ignacio",
      link: "https://www.facebook.com/angelo.ignacio.718/",
    },
    {
      display: "LinkedIn: Mark Angelo F Ignacio",
      link: "https://www.linkedin.com/in/mark-angelo-ignacio-b0638720b/",
    },
    {
      display: "Google Search : Mark Angelo Ignacio Web",
      link: "https://www.google.com/search?q=mark+angelo+ignacio+website&oq=&gs_lcrp=EgZjaHJvbWUqBggCEEUYOzIGCAAQRRg9MgYIARBFGDsyBggCEEUYOzIGCAMQRRg5MgYIBBBFGDsyBggFEEUYOzIGCAYQRRg8MgYIBxBFGDwyBggIEEUYPNIBCDIyNzhqMGoxqAIAsAIA&sourceid=chrome&ie=UTF-8"
    }
  ];
  return (
    <>
      <Navigation />
      <Container className="parent-container">
        <MainContainer>
          <ContactMeTitle>
            Con <br /> tact Me
          </ContactMeTitle>
          <ContactList>
            {contact.map((contact, i) => (
              <List key={i} href={contact.link} target="_blank">
                <img src={b} alt="ic-bullet" href={contact.link} />
                {contact.display}
              </List>
            ))}
          </ContactList>
          <Context>
            with Job Invitation <br /> 50+
          </Context>
          <Context style={{ color: styles.cherry }}>
            with Job offers <br /> 9
          </Context>
        </MainContainer>
      </Container>
      <Footer />
    </>
  );
}

const Container = styled.div`
  background-color: ${styles.cherry};
  display: flex;
  height: 500px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MainContainer = styled.main`
  max-width: 1537px;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 0%;
  background-color: transparent;
  background-color: ${styles.dark};
  color: white;
  gap: 24px;
`;
const ContactMeTitle = styled.h1`
  height: auto;
  width: 151px;
  font-family: ${styles.regular};
  color: ${styles.white};
  font-size: 3.875rem;
  text-transform: uppercase;
  padding: 0px;
  line-height: 49px;
`;

const ContactList = styled.ul`
  list-style: none;
`;

const List = styled.a`
  list-style: none;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: ${styles.medium};
  margin: 14px 0px;
  cursor: pointer;
  text-decoration: none;
  color: ${styles.white};
`;
const Context = styled.h1`
  height: auto;
  width: 151px;
  font-family: ${styles.black};
  color: ${styles.white};
  font-size: 1.5rem;
  text-transform: lowercase;
`;

export default Contact;
