import styled from "styled-components";
import styles from "../../src/2024/assets/styles";
export const PhotoWeb = ({ img, title, context,color }) => {
  return (
    <Container color={color}>
      <ImageHolder src={img} alt={title} color={color} />
      <Body color={color}>
        <Title>{title}</Title>
        <Content>{context}</Content>
      </Body>
    </Container>
  );
};

const Container = styled.div`
  height: 350px;
  width: 450px;
  background-color: ${(props) => props.color};
  box-sizing: content-box;
  padding: 0px;
  margin: 3px;
  border-radius: 8px;
  @media (max-width: 550px) {
    height: auto;
    min-height: 200px;
    padding: 4px 4px;
    width: 350px;
  }
`;

const ImageHolder = styled.img`
  width: 100%;
  height: 242px;
  object-fit: cover;
  border-radius: 8px;
`;
const Body = styled.div`
  height: auto;
  padding: 0px 21px;
  align-self: start;
  padding: 13px 21px;
  border-radius: 20px;
`;
const Title = styled.h5`
  font-size: 1.5rem;
  font-family: ${styles.heavy};
  color: ${styles.white};
  margin: 0px;
  text-transform: capitalize;
`;
const Content = styled.p`
  font-size: 1.125rem;
  font-family: ${styles.regular};
  color: ${styles.white};
  margin-top: 10px;
`;
