import "./fonts/font.css";

const colors = {
  white: "#ffffff",
  orange: "#FF9646",
  lightorange: "#FF9E65",
  black: "#000000",
  whitesmoke: "#E8E8E8",
  ash: "#667582",
  gray: "#707070",
  dark: "#00040D",
  cherry: "#FF465F",
  transparent: "transparent",
};

const font = {
  ultralight: "UltraThin",
  thin: "Thin",
  light: "Light",
  regular: "Regular",
  medium: "Medium",
  bold: "Bold",
  boldItalic: "BoldItalic",
  italic: "Italic",
  semibold: "SemiBold",
  black: "Black",
  heavy: "Heavy",
};
const styles = {
  ...colors,
  ...font,
};
export default styles;
