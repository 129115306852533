import { useState, useEffect } from "react";
import styles from "../assets/styles";
import styled from "styled-components";
import icChevron from "../assets/image/chevron-left.svg";
import icLeft from "../assets/image/ic-leftarrow.svg";
import b from "../assets/image/bullet.svg";
import icChevronRight from "../assets/image/chevron-right.svg";
import { _dataMobile, _dataWeb } from "../Page/mockProject";
import "../components/css/MobilePortfolio.css";
//import web from "../project/2.png";

export const WebCard = () => {
  const [n, setn] = useState(0);
  const [web, setweb] = useState(_dataWeb[0]);
  const count = _dataWeb.length - 1;
  const [mi, setmi] = useState(0);
  const countiming = web.image.length;

  useEffect(() => {
    setweb(_dataWeb[n]);
    setmi(0);
  }, [n]);

  const nextPreview = () => {
    if (n >= count) {
      setn(0);
    } else {
      setn(n + 1);
    }
  };
  const prevePreview = () => {
    if (n <= 0) {
      setn(count);
    } else {
      setn(n - 1);
    }
  };

  const nextHeadImg = () => {
    if (mi >= countiming - 1) {
      setmi(0);
    } else {
      setmi(mi + 1);
    }
  };

  const prevtHeadImg = () => {
    if (mi < 1) {
      setmi(countiming - 1);
    } else {
      setmi(mi - 1);
    }
  };
  return (
    <Container>
      <div
        style={{
          height: 18,
          width: "100%",
          backgroundColor: styles.cherry,
          marginBottom: 24,
        }}
      ></div>

      <MainContainer className="maincontainer" img={web?.image[mi]}>
        <Arrow className="arrow" onClick={() => prevePreview()}>
          <img className="chevronl" src={icChevron} alt="chevronleft" />
          <p
            style={{
              fontFamily: styles.medium,
              fontSize: 16,
              color: styles.cherry,
            }}
          >
            {_dataWeb[n === 0 ? count : n - 1]?.title}
          </p>
        </Arrow>
        <Display className="Display">
          <WebSection className="WebSection">
            {web?.image?.length > 1 && (
              <ArrowSection className="ArrowSection">
                <img
                  onClick={() => prevtHeadImg()}
                  src={icLeft}
                  style={{ paddingBottom: 0 }}
                />
                <img
                  onClick={() => nextHeadImg()}
                  src={icLeft}
                  style={{ paddingTop: 0, transform: "rotate(-180deg)" }}
                />
              </ArrowSection>
            )}

            <ImageList className="ImageListWeb">
              {web?.image?.length > 0 &&
                web?.image?.map((image, i) => (
                  <img
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "fill",
                    }}
                    src={image}
                    key={i}
                    alt="Mark Angelo Sample Work"
                    onClick={() => setmi(i)}
                  />
                ))}
            </ImageList>
            <WebDisplay className="WebDisplay" src={web?.image[mi]} />
          </WebSection>
          <DesSection className="DesSection">
            <div>
              <TitleName className="TitleName">{web?.title}</TitleName>
              <TypeName className="TypeName">{web?.type}</TypeName>
            </div>
            <div
              className="webleft"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <Description className="Description DescriptionWeb">
                {web?.description}
              </Description>
              <ul
                className="ULList"
                style={{
                  display: "flex",
                  width: "100%",

                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0px",
                  gap: 18,
                }}
              >
                {web?.tech?.map((skill, i) => (
                  <List className="List" key={i}>
                    <img src={b} alt="ic-bullet" /> {skill}
                  </List>
                ))}
              </ul>
            </div>
          </DesSection>
        </Display>

        <Arrow className="arrowr" onClick={() => nextPreview()}>
          <p
            style={{
              fontFamily: styles.medium,
              fontSize: 16,
              color: styles.cherry,
            }}
          >
            {_dataWeb[n >= count ? 0 : n + 1]?.title}
          </p>
          <img className="chevronl" src={icChevronRight} alt="chevron right" />
        </Arrow>
      </MainContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  background-color: ${styles.ash};
  width: 100%;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: white;
  margin: 50px 0px;
  padding-bottom: 50px;
`;
const MainContainer = styled.main`
  max-width: 1537px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 0%;
  background-color: transparent;
  height: auto;
  gap: 42px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.85)),
    url(${(props) => props.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
const Arrow = styled.section`
  width: 100px;
  display: flex;
  align-content: center;
  justify-content: space-between;
  cursor: pointer;
`;
const Display = styled.main`
  flex: 1;
  height: auto;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  gap: 0px;
`;

const WebSection = styled.section`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
const ImageList = styled.section`
  flex: 0.5;
  height: 375px;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  border-radius: 4px;

  &::-webkit-scrollbar {
    width: 0px; /* Adjust width as needed */
    background-color: #f5f5f5; /* Light background color */
  }
`;
const WebDisplay = styled.img`
  flex: 1;
  width: 676px;
  height: 450px;
  background-color: none;
  object-fit: contain;
  border-radius: 8px;
`;

const ArrowSection = styled.div`
  width: 100%;
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const DesSection = styled.section`
  flex: 0.5;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  flex-wrap: no-wrap;
`;

const TitleName = styled.h1`
  font-family: ${styles.black};
  font-size: 48px;
  text-transform: uppercase;
  margin: 0px;
`;
const TypeName = styled.h4`
  font-family: ${styles.regular};
  font-weight: 400;
  font-size: 24px;
  text-transform: capitalize;
  margin-top: 18px;
`;
const Description = styled.p`
  font-family: ${styles.regular};
  font-weight: 400;
  font-size: 18px;
  margin: 24px 0px;
  width: 425px;
  height: 80px;
  word-break: break-word;
  text-align: right;
  line-height: 28px;
`;
const List = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: ${styles.medium};
`;
