import Navigation from "../components/Navigations";
import styled from "styled-components";
import Footer from "../components/Footer";
import styles from "../assets/styles";
import tech from "../assets/image/tech.svg";
import "../components/css/MobileLanding.css";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { itemDatas, itemDatasWeb, itemMobile } from "./mockProject";
import { useEffect, useState } from "react";
import { MobileCard } from "../components/MobileCard";
import { WebCard } from "../components/WebCard";
function Portfolio() {
  const [filter, setFilter] = useState("mobile");
  const [data, setData] = useState(itemMobile);

  useEffect(() => {
    datalist(filter);
  }, [filter]);

  const datalist = (title) => {
    switch (title) {
      case "mobile":
        setData(itemMobile);
        break;
      case "web":
        setData(itemDatasWeb);
      default:
        break;
    }
  };
  return (
    <>
      <Navigation />

      <Container className="parent-container">
        <HeadingContainer className="Heading">
          <GreetingBox className="GreetingBox">
            <Name className="Name">
              Portfolio <br />
              <span style={{ color: styles.cherry }}>Portfolio</span>
            </Name>
          </GreetingBox>
          <img
            className="myphoto"
            src={tech}
            style={{ marginTop: "0px", zIndex: 999, flex: 0.5 }}
            alt="Portfolio List"
          />
        </HeadingContainer>

        <MobileCard />
        <WebCard />
      </Container>
      <Footer />
    </>
  );
}
const Container = styled.div`
  background-color: ${styles.dark};
  display: flex;
  height: auto;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 73px 0;
`;
export const MainContainer = styled.main`
  max-width: 1537px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 0%;
  background-color: transparent;
  background-color: ${styles.dark};
`;
const HeadingContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 50px 0px;
  `;

const GreetingBox = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 2;
  width: 496px;
`;
const Name = styled.h1`
  padding: 0;
  margin: 0;
  color: ${styles.white};
  font-family: ${styles.heavy};
  text-transform: uppercase;
  font-size: 3.875rem;
  align-center: center;
  line-height: 42px;
`;



export default Portfolio;
