import styled from "styled-components";
import styles from "../assets/styles";
import { Link } from "react-router-dom";
import "./css/MobileNavigation.css";
import icMenu from "../assets/image/ic-menu.svg";
import { useEffect, useState } from "react";
function Navigation({ backgroundColor }) {
  const [modal, setmodal] = useState(false);
  const [backgroundcolor, setBackgroundColor] = useState(backgroundColor);

  useEffect(() => {
    setBackgroundColor(backgroundColor);
  }, [backgroundColor]);

  const openModal = () => {
    setmodal((prevState) => !prevState);
  };
  return (
    <>
      <NavigationContainer
        className="NavigationContainer"
        color={backgroundcolor || styles.dark}
      >
        <div
          className="MainNavContainer"
          style={{
            width: "100%",
            maxWidth: "1550px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 6%",
          }}
        >
          <NavigationTitle className="NavigationTitle" to="/">
            Mark Angelo Ignacio Work
          </NavigationTitle>
          <NavBox className="Navbox">
            <NavLink to="/" className="nav-link">
              About
            </NavLink>
            <NavLink to="/careerv2" className="nav-link">
              Career
            </NavLink>
            <NavLink to="/portfolio" className="nav-link">
              Portfolio
            </NavLink>
            {/* <NavLink to="/blogs" className="nav-link">
              Programs
            </NavLink>*/}
            <NavLink to="/contact" className="nav-link">
              Contact
            </NavLink>
          </NavBox>
          <img
            className="MenuIcon"
            style={{ display: "none" }}
            alt=""
            src={icMenu}
            onClick={() => {
              openModal();
            }}
          />
        </div>
      </NavigationContainer>
      {modal && (
        <ModalContainer className="ModalContainer">
          <Modal>
            <h1
              style={{ position: "relative", alignSelf: "flex-end" }}
              onClick={() => {
                openModal();
              }}
            >
              X
            </h1>

            <ModalLink to="/">About</ModalLink>
            <ModalLink to="/careerv2">Career</ModalLink>
            <ModalLink to="/portfolio">Portfolio</ModalLink>
            {/* <ModalLink to="/blogs">Programs</ModalLink>*/}
            <ModalLink to="/contact">Contact ME</ModalLink>
          </Modal>
        </ModalContainer>
      )}
    </>
  );
}

const NavigationContainer = styled.div`
  display: flex;
  position: relative;
  z-index: 998;
  width: 100%;
  height: 198px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props?.color};
  background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.1));
`;

const NavigationTitle = styled(Link)`
  font-family: ${styles.black};
  font-size: 1.5rem;
  color: ${styles.white};
  width: 50px;
  z-index: 1000;
  text-decoration: none;
  cursor: pointer;
`;
const NavBox = styled.nav`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-center: center;
  gap: 55px;
`;
const NavLink = styled(Link)`
  text-decoration: none;
  color: ${styles.white};
  font-family: ${styles.bold};
  font-size: 1.5rem;
  text-transform: lowercase;
  pointer: cursor;
`;

// Modal
const ModalContainer = styled.div`
  display: flex;
  z-index: 1000;
  background-color: ${styles.cherry};
  position: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20% 0;
`;
const Modal = styled.main`
  display: flex;
  flex-flow: column nowrap;
  flex-direction: column;
  justify-content: flex-start;
  gap: 32px;
`;

const ModalLink = styled(Link)`
  text-decoration: none;
  color: ${styles.white};
  font-family: ${styles.bold};
  font-size: 32px;
  text-transform: lowercase;
  pointer: cursor;
  text-align: left;
`;
export default Navigation;
