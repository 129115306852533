import { useState, useEffect } from "react";
import styles from "../assets/styles";
import styled from "styled-components";
import icChevron from "../assets/image/chevron-left.svg";
import icLeft from "../assets/image/ic-leftarrow.svg";
import b from "../assets/image/bullet.svg";
import icChevronRight from "../assets/image/chevron-right.svg";
import { _dataMobile } from "../Page/mockProject";
import "../components/css/MobilePortfolio.css";
export const MobileCard = () => {
  const [mp, setmp] = useState(0);
  const [mobile, setmobile] = useState(_dataMobile[0]);
  const length = Object.keys(_dataMobile).length - 1;
  const countiming = Object.keys(_dataMobile[mp]?.image)?.length - 1;

  const [mi, setmi] = useState(0);
  useEffect(() => {
    setmobile(_dataMobile[mp]);
    setmi(0);
  }, [mp]);

  const nextMobilePreview = () => {
    if (mp >= length) {
      setmp(0);
    } else {
      setmp(mp + 1);
    }
  };
  const prevMobilePreview = () => {
    if (mp <= 0) {
      setmp(length);
    } else {
      setmp(mp - 1);
    }
  };

  const nextHeadImg = () => {
    if (mi >= countiming) {
      setmi(0);
    } else {
      setmi(mi + 1);
    }
  };

  const prevtHeadImg = () => {
    if (mi < 1) {
      setmi(countiming);
    } else {
      setmi(mi - 1);
    }
  };

  return (
    <Container>
      <div
        style={{
          height: 18,
          width: "100%",
          backgroundColor: styles.cherry,
          marginBottom: 50,
        }}
      ></div>
      <MainContainer className="maincontainer">
        <Arrow className="arrow" onClick={() => prevMobilePreview()}>
          <img className="chevronl" src={icChevron} alt="chevronleft" />
          <p
            style={{
              fontFamily: styles.medium,
              fontSize: 16,
              color: styles.cherry,
            }}
          >
            {_dataMobile[mp === 0 ? length : mp - 1]?.title}
          </p>
        </Arrow>
        <Display className="Display">
          <MobileLeftSection className="MobileLeftSection">
            <img
              onClick={() => prevtHeadImg()}
              src={icLeft}
              style={{ paddingBottom: 50 }}
            />
            <MobileDisplay
              className="MobileDisplay"
              src={mobile?.image[mi]}
              img={mobile?.image[mi]}
            />
            <img
              onClick={() => nextHeadImg()}
              src={icLeft}
              style={{ paddingTop: 50, transform: "rotate(-180deg)" }}
            />
          </MobileLeftSection>

          {/* DETAILS DISPLAY */}
          <MobileLeftSection
            className="MobileRightSection"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "0px 0px",
            }}
          >
            <TitleName className="TitleName">{mobile?.title}</TitleName>
            <TypeName className="TypeName">{mobile?.type}</TypeName>
            <Description className="Description">
              {mobile?.description}
            </Description>
            <ul
              className="ULList"
              style={{
                display: "flex",
                width:"100%",
                alignItems: "center",
                justifyContent: "center",
                margin: "27px 0 0 0 ",
                padding: "0px 40px",
                gap: 18,
              }}
            >
              {mobile?.tech.map((skill, i) => (
                <List key={i}>
                  <img src={b} alt="ic-bullet" /> {skill}
                </List>
              ))}
            </ul>

            <ImageList className="ImageList">
              {mobile?.image.map((img, i) => (
                <img
                  onClick={() => setmi(i)}
                  key={i}
                  style={{
                    width: 120,
                    height: "100%",
                    objectFit: "fill",
                    border: `${mi === i ? "2px solid white" : "0"}`,
                  }}
                  src={img}
                  alt={i}
                />
              ))}
            </ImageList>
          </MobileLeftSection>
        </Display>
        <Arrow className="arrowr" onClick={() => nextMobilePreview()}>
          <p
            style={{
              fontFamily: styles.medium,
              fontSize: 16,
              color: styles.cherry,
            }}
          >
            {_dataMobile[mp >= length ? 0 : mp + 1]?.title}
          </p>
          <img className="chevronl" src={icChevronRight} alt="chevron right" />
        </Arrow>
      </MainContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  margin-top: 48px;
  padding: 50px 0px;
`;
const MainContainer = styled.main`
  max-width: 1537px;
  width: 100%;
  display: flex;
  flex-wrap: no-wrap;
  justify-content: center;
  align-items: center;
  gap: 57px;
  background-color: transparent;
`;
const Display = styled.main`
  flex: 1;
  height: auto;
  max-width: 960px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
`;
const Arrow = styled.section`
  width: 120px;
  display: flex;
  align-content: center;
  justify-content: space-between;
  cursor: pointer;
`;
const MobileLeftSection = styled.section`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  gap: 24px;
`;
const MobileDisplay = styled.img`
  width: 288.55px;
  height: 512.96px;
  background-color: gray;
  object-fit: contain;
  border-radius: 8px;
  border: 4px solid ${styles.ash};
  background-image: linear-gradient(
      rgba(33, 45, 60, 0.8),
      rgba(33, 45, 60, 0.8)
    ),
    url(${(props) => props.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
const TitleName = styled.h1`
  font-family: ${styles.black};
  font-size: 52px;
  text-transform: uppercase;
  margin: 0px;
`;
const TypeName = styled.h4`
  font-family: ${styles.regular};
  font-weight: 400;
  font-size: 24px;
  text-transform: capitalize;
  margin-top: 18px;
`;
const Description = styled.p`
  font-family: ${styles.regular};
  font-weight: 400;
  font-size: 18px;
  margin: 0px;
  width: 425px;
  height: 85px;
  word-break: break-word;
  text-align: justify;
  line-height: 28px;
  padding: 10px 0;
`;
const List = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: ${styles.medium};

`;
const ImageList = styled.section`
  width: 420px;
  height: 189.91px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  border-radius: 4px;

  && ::-webkit-scrollbar {
    display: none;
  }
`;
