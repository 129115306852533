import styles from "../assets/styles";
import styled from "styled-components";
import { Link } from "react-router-dom";
import "./css/MobileLanding.css";
export const SkillSection = () => {
  return (
    <Container className="skill-container">
      <MainContainer className="skill-main">
        <ProjectHandle>
          Project <br /> Handled
        </ProjectHandle>
        <SectionContainer>
          <Box style={{ backgroundColor: `${styles.cherry}` }} />
          <DetailBox>
            <TitleSection>Website</TitleSection>
            <TitleNumber>
              4 <span style={{ fontSize: 16 }}>Total</span>
            </TitleNumber>
          </DetailBox>
        </SectionContainer>
        <SectionContainer>
          <Box style={{ backgroundColor: `${styles.orange}` }} />
          <DetailBox>
            <TitleSection>Mobile</TitleSection>
            <TitleNumber>
              {" "}
              6 <span style={{ fontSize: 16 }}>Total</span>
            </TitleNumber>
          </DetailBox>
        </SectionContainer>
        <HeadingName className="headingname" to={"career"}>
          visit more
        </HeadingName>
      </MainContainer>
    </Container>
  );
};

const Container = styled.div`
  height: auto;
  width: 100%;
  background-color: ${styles.dark};
  display: flex;
  flex-wrap: wrap;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 117px 0px;
`;
const MainContainer = styled.main`
  max-width: 1537px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 57px;
  background-color: transparent;
`;

const ProjectHandle = styled.h1`
  font-family: ${styles.black};
  color: ${styles.white};
  text-transform: uppercase;
  font-size: 2.563rem;
  text-align: center;
  background-color: ${styles.ash};
  padding: 32px;
  border: 8px solid ${styles.white};
  border-radius: 8px;
  transform: rotate(-2deg);
`;
const SectionContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
`;
const HeadingName = styled(Link)`
  text-decoration: underline;
  font-family: ${styles.medium};
  font-size: 1.75rem;
  color: ${styles.white};
  text-transform: lowercase;
  align-self: flex-end;
  float: right;
  cursor: pointer;
`;
const Box = styled.div`
  height: 120px;
  width: 120px;
  border: 10px solid white;
  float: left;
  z-index: 1;
  top: -250px;
  margin-right: -95px;
  margin-top: -40px;
  border-radius: 8px;
`;
const DetailBox = styled.div`
  width: 246px;
  height: 227px;
  background-color: ${styles.white};
  float: right;
  z-index: 20;
  border-radius: 8px;
  padding: 0px 0px;
  text-align: center;
`;

const TitleSection = styled.h1`
  font-family: ${styles.black};
  color: ${styles.cherry};
  text-transfrom: lowercase;
  font-size: 2.938rem;
  text-align: center;
`;

const TitleNumber = styled.h1`
  font-family: ${styles.black};
  color: ${styles.dark};
  text-transfrom: lowercase;
  font-size: 5.5rem;
  text-align: center;
  margin-top: -32px;
`;
const List = styled.li`
  list-style: none;
  font-family: ${styles.medium};
  color: ${styles.ash};
  font-size: 1.2rem;
  text-transform: lowercase;
  margin: 5px 0px;
`;
