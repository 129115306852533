import Photo1 from "../images/PhilForm.png";
import Photo2 from "../images/PhilLandingPages.png";
import Photo3 from "../images/Photo3.png";
import Photo4 from "../images/Photo4.jpg";
import Photo5 from "../images/Photo5.jpg";
import Photo6 from "../images/Photo6.jpeg";
import Photo7 from "../images/1.png";
import Photo8 from "../images/2.png";
import Photo9 from "../images/5.jpg";
import Photo10 from "../images/6.png";
import Photo11 from "../images/7.png";

// Website
import Web1 from "../images/3.png";
import Web2 from "../images/Cr2.png";
import Web3 from "../images/netflix.jpg";
import Web4 from "../images/11.png";
import Web5 from "../images/Insight.jpeg";
import Web6 from "../images/Story page.jpeg";
export const photoData = [
  {
    title: "",
    context: "1",
    image: Photo1,
  },
  {
    title: "2",
    context: "2",
    image: Photo2,
  },
  {
    title: "2",
    context: "2",
    image: Photo3,
  },
  {
    title: "2",
    context: "2",
    image: Photo11,
  },
  {
    title: "2",
    context: "2",
    image: Photo5,
  },
  {
    title: "2",
    context: "2",
    image: Photo6,
  },
  {
    title: "2",
    context: "2",
    image: Photo7,
  },
  {
    image: Photo8,
  },
  {
    image: Photo9,
  },
  {
    image: Photo10,
  },
];

export const photoWebsite = [
  {
    image: Web1,
  },
  {
    image: Web2,
  },
  {
    image: Web3,
  },
  {
    image: Web4,
  },
  {
    image: Web5,
  },
  {
    image: Web6,
  },
  {
    image: Photo4,
  },
];
