import styles from "../assets/styles";
import Navigation from "../components/Navigations";
import styled from "styled-components";
import Footer from "../components/Footer";
import { SectionTag } from "../components/SectionTag";
import { useState } from "react";
import { ProjectCard } from "../components/ProjectCard";
import { _handledproject, _handledproject2 } from "./mockProject";
import "../components/css/MobileCareer.css";
import { PhotoWeb } from "../../components/PhotoWeb";

//Timeline
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

function NewCareer() {
  const [extend, setExtend] = useState(false);

  const expandmore = () => {
    setExtend((prevState) => !prevState);
  };

  const _details1 = [
    `responsible for developing the user interface of the
    application using figma based design provided by the
    ui/ux designer and approved from clients.`,
    `responsible for developing the functionalities given by
    the project manager.`,
    `responsible for publishing in google and apple store.`,
    `providing a quality services in western countries and asia.`,
    `the technology used react and other libraries for
    development and api for database connection for web
    development projects.`,
  ];

  return (
    <>
      <Navigation backgroundColor={styles.dark} />
      <CareerSection className="CareerSection">
        <MainContainer className="MainContainer">
          <SectionTag title={"Career"} color={styles.dark} />
          <DetailSection
            className="DetailSection"
            style={{ height: extend && "auto" }}
          >
            <div>
              <div
                className="DetailLAbel"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "110%",
                }}
              >
                <Jobname>Web and Mobile Developer</Jobname>
                <Company>Rooche Digital Company - Quezon City</Company>
              </div>

              <Date>April 24, 2022 – April 26, 2024</Date>
            </div>
            <UL>
              <Li className="Li">{_details1[0]}</Li>
              {extend && (
                <>
                  {_details1.map(
                    (row, index) =>
                      index > 0 && (
                        <Li className="Li" key={index}>
                          {row}
                        </Li>
                      )
                  )}
                </>
              )}
            </UL>

            <Company
              style={{ cursor: "pointer", alignItems: "flex-end" }}
              onClick={() => expandmore()}
            >
              {extend ? "Minimize" : "Expand More"}
            </Company>
          </DetailSection>
        </MainContainer>
      </CareerSection>
      <Project className="Project">
        <MainContainer className="MainContainer">
          <ProjectTitle className="ProjectTitle">Project Timeline</ProjectTitle>
          <ApplicationBox className="ApplicationBox">
            <VerticalTimeline>
              {_handledproject.map((row, index) => (
                <VerticalTimelineElement
                  key={index}
                  style={{ width: "auto !important", color: styles.white }}
                  contentStyle={{
                    background: `${
                      index % 2 === 1 ? styles.cherry : styles.gray
                    }`,
                    color: "#fff",
                    fontFamily: styles.heavy,
                  }}
                  contentArrowStyle={{
                    borderRight: `7px solid  ${
                      index % 2 === 1 ? styles.cherry : styles.gray
                    }`,
                  }}
                  date={`${row.a} - ${row.b}`}
                  iconStyle={{
                    background: `${
                      index % 2 === 0 ? styles.cherry : styles.orange
                    }`,
                    color: "#fff",
                  }}
                  icon={<p style={{ textAlign: "center" }}></p>}
                >
                  <h3 className="vertical-timeline-element-title">
                    {row.title}
                  </h3>
                  <h4 className="vertical-timeline-element-subtitle">
                    {row.tech}
                  </h4>
                  {/* <ProjectCard
                  title={row.title}
                  color={index % 2 === 0 ? styles.cherry : styles.orange}
                  image={row.img}
                  star={row.start}
                  types={row.type}
                  mobile={row.mobile}
                  key={index}
                /> */}
                </VerticalTimelineElement>
              ))}
            </VerticalTimeline>
          </ApplicationBox>
        </MainContainer>
      </Project>
      <Project className="Project" style={{ backgroundColor: styles.ash }}>
        <MainContainer>
          <ProjectTitle className="ProjectTitle">Project Handled</ProjectTitle>
          <ApplicationBox
            className="ApplicationBox"
            style={{
              width: "100%",
              padding: "0px",
              marginBottom: "24px",
            }}
          >
            {_handledproject
              .filter((value) => value.mobile === true)
              .map((row, index) => (
                <ProjectCard
                  title={row.title}
                  color={index % 2 === 0 ? styles.cherry : styles.orange}
                  image={row.img}
                  star={row.start}
                  types={row.type}
                  mobile={row.mobile}
                  key={index}
                  show={row.show}
                />
              ))}
          </ApplicationBox>
          <ApplicationBox
            className="ApplicationBox"
            style={{
              width: "100%",
              padding: "0px",
            }}
          >
            {_handledproject
              .filter(
                (value) =>
                  value.mobile === false &&
                  value.title !== "RESIGNED FOR COLLEGE"
              )
              .map((row, index) => (
                <ProjectCard
                  title={row.title}
                  color={index % 2 === 0 ? styles.cherry : styles.orange}
                  image={row.img}
                  star={row.start}
                  types={row.type}
                  mobile={row.mobile}
                  key={index}
                  show={row.show}
                />
              ))}
          </ApplicationBox>
        </MainContainer>
      </Project>
      {/* //2nd Job */}
      <CareerSection className="CareerSection">
        <MainContainer className="MainContainer">
          <SectionTag title={"Career"} color={styles.dark} />
          <DetailSection
            className="DetailSection"
            style={{ height: extend && "auto" }}
          >
            <div>
              <div
                className="DetailLAbel"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "120%",
                  flexWrap: "wrap",
                }}
              >
                <Jobname>Web and Application Developer</Jobname>
                <Company>Top Notch Construction, Cavite</Company>
              </div>
              <Date> August 25,2021 November 25,2021</Date>
            </div>
            <UL>
              <Li className="Li">
                Responsible for Designs, Front End Development using Adobe XD,
                HTML, CSS with Bootstrap, JavaScript (DOM, jQuery) and ASP.NET
                MVC Core and SQL as Backend.
              </Li>
            </UL>

            <Company
              style={{ cursor: "pointer", alignItems: "flex-end" }}
              onClick={() => expandmore()}
            ></Company>
          </DetailSection>
        </MainContainer>
      </CareerSection>
      <Project className="Project">
        <ProjectTitle className="ProjectTitle">Project Handled</ProjectTitle>
        <ApplicationBox className="ApplicationBox">
          {_handledproject2.map((row, index) => (
            <PhotoWeb
              title={row.title}
              img={row.img}
              context={row.type}
              key={index}
            />
          ))}
        </ApplicationBox>
      </Project>
      <Footer />
    </>
  );
}

const CareerSection = styled.section`
  height: auto;
  background-color: ${styles.cherry};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2.5%;
  gap: 10px;
`;

const MainContainer = styled.div`
  max-width: 1550px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: transparent;
`;
const ICon = styled.img`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 15vh;
`;
// Projects
const Project = styled.section`
  height: auto;
  background-color: ${styles.dark};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 30px 8%;
`;
const ApplicationBox = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 18px;
  flex-wrap: wrap;
`;

const ProjectTitle = styled.h1`
  color: ${styles.white};
  border: 4px solid ${styles.white};
  border-radius: 8px;
  padding: 28px 14px;
  font-family: ${styles.bold};
  background-color: ${styles.orange};
  font-size: 2.75rem;
  align-self: flex-start;
`;
const DetailSection = styled.section`
  flex: 1;
  background-color: ${styles.ash};
  height: 325px;
  padding: 0px 24px;
  transition: height 0.5s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border: 4px solid ${styles.white};
  border-radius: 8px;
  flex-wrap: wrap;
  gap: 24px;
`;
const Jobname = styled.h1`
  color: ${styles.white};
  font-family: ${styles.black};
  font-size: 2.313rem;
  margin-bottom: 14px;
`;
const Company = styled.h2`
  font-family: ${styles.medium};
  font-size: 1.5rem;
  color: ${styles.white};
  margin-bottom: 10px;
`;
const Date = styled.p`
  font-family: ${styles.medium};
  font-size: 1.188rem;
  color: ${styles.white};
  margin: 0px;
`;
const UL = styled.ul`
  transition-duration: 5s;
  transition-delay: 2s;
`;
const Li = styled.li`
  color: ${styles.white};
  font-family: ${styles.medium};
  text-transform: lowercase;
  font-size: 24px;
  word-break: break-word;
`;

export default NewCareer;
