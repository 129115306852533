import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import AboutMe from "./pages/AboutMe";
import Career from "./pages/Career";
import Portfolio from "./pages/Portfolio";
import Blogs from "./pages/Blogs";

// 2024
import NewHome from "./2024/Page/home";
import NewCareer from "./2024/Page/career";
import NewCareerV2 from "./2024/Page/careernew";
import NewPortfolio from "./2024/Page/portfolio";
import NewContact from "./2024/Page/contact";
import { useState, useEffect } from "react";
function App() {
  const [route] = useState(2024);

  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }
  return (
    <div>
      {route === 2023 && (
        <Router>
          <Routes>
            <Route element={<AboutMe />} path="/" />
            <Route element={<Career />} path="/career" />
            <Route element={<Portfolio />} path="/portfolio" />
            <Route element={<Blogs />} path="/blogs" />
          </Routes>
        </Router>
      )}
      {route === 2024 && (
        <Router>
          <ScrollToTop />
          <Routes>
            <Route element={<NewHome />} path="/" />
            <Route element={<NewCareer />} path="/career" />
            <Route element={<NewCareerV2 />} path="/careerv2"/>
            <Route element={<NewPortfolio />} path="/portfolio" />
            <Route element={<NewContact />} path="/contact" />
          </Routes>
        </Router>
      )}
    </div>
  );
}

export default App;
